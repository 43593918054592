import { createSlice } from "@reduxjs/toolkit";

interface MobileState {
    mobile: boolean;
}

const initialState: MobileState = { mobile: false };

export const mobileSlice = createSlice({
    name: "mobile",
    initialState,
    reducers: {
        setAppAsMobile: (state) => {
            state.mobile = true;
        },
        setAppAsWebsite: (state) => {
            state.mobile = false;
        }
    },
});

export const { setAppAsMobile, setAppAsWebsite } = mobileSlice.actions;
export default mobileSlice.reducer;
