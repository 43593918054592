import { PartnerKeys } from "src/constants/PartnerKeys";


import MeuPedagioLogo from "src/assets/images/partners/meupedagio/logo.svg";
import MeuPedagioMinLogo from "src/assets/images/partners/meupedagio/min-logo.svg";

import EprLogo from "src/assets/images/partners/epr/logo.png";

import EcoNoroesteLogo from "src/assets/images/partners/econoroeste/logo.png";
import EcoNoroesteMinLogo from "src/assets/images/partners/econoroeste/min-logo.png";

import SignInBackground from "src/assets/images/partners/meupedagio/sign-in.png";
import SignUpBackground from "src/assets/images/partners/meupedagio/sign-up.svg";

import Error400 from "src/assets/images/partners/meupedagio/error-400.svg"
import Error404 from "src/assets/images/partners/meupedagio/error-404.svg"
import ConfirmEmailImage from "src/assets/images/partners/meupedagio/confirm-email.svg"
import GoodbyeImage from "src/assets/images/partners/meupedagio/goodbye.svg"

import OnBoardingCongratulations from "src/assets/images/partners/meupedagio/onboarding/img-parabens.svg";

export enum WhitelabelHookType {
    LOGO = 'logo',
    MIN_LOGO = 'minLogo',
    SIGN_IN_BACKGROUND = 'signInBackground',
    SIGN_UP_BACKGROUND = 'signUpBackground',
    DASH_TITLE = 'dashTitle',
    ERROR_400 = 'error400',
    ERROR_404 = 'error404',
    CONFIRM_EMAIL = 'confirmEmail',
    IS_PARTNER = 'isPartner',
    TITLE = 'title',
    ONBOARDING_STEP1_CONGRATULATIONS = 'onboardingStep1Congratulations',
    ONBOARDING_STEP2_CREDIT_CARD = 'onboardingStep2CreditCard',
    CNPJ = 'cnpj',
    GOODBYE = 'goodbye',
}

export default function useWhitelabel(type: WhitelabelHookType) {
    const clientId = import.meta.env.VITE_APP_CLIENT_ID;
    switch (type) {
        case WhitelabelHookType.LOGO:
            return clientId === PartnerKeys.ECONOROESTE
                ? EcoNoroesteLogo
                : clientId === PartnerKeys.EPR
                    ? EprLogo
                    : MeuPedagioLogo;
        case WhitelabelHookType.MIN_LOGO:
            return clientId === PartnerKeys.ECONOROESTE
                ? EcoNoroesteMinLogo
                : clientId === PartnerKeys.EPR
                    ? EprLogo
                    : MeuPedagioMinLogo;
        case WhitelabelHookType.SIGN_IN_BACKGROUND:
            return SignInBackground;
        case WhitelabelHookType.SIGN_UP_BACKGROUND:
            return SignUpBackground;
        case WhitelabelHookType.DASH_TITLE:
        case WhitelabelHookType.TITLE:
            return clientId === PartnerKeys.ECONOROESTE
                ? "EcoNoroeste"
                : clientId === PartnerKeys.EPR
                    ? "Grupo EPR"
                    : "Meu Pedágio";
        case WhitelabelHookType.ERROR_400:
            return Error400;
        case WhitelabelHookType.ERROR_404:
            return Error404;
        case WhitelabelHookType.CONFIRM_EMAIL:
            return ConfirmEmailImage;
        case WhitelabelHookType.IS_PARTNER:
            return `${clientId !== PartnerKeys.MEU_PEDAGIO}`;
        case WhitelabelHookType.ONBOARDING_STEP1_CONGRATULATIONS:
            return OnBoardingCongratulations;
        case WhitelabelHookType.CNPJ:
            return "51.111.347/0001-42";
        case WhitelabelHookType.GOODBYE:
            return GoodbyeImage;
    }
}