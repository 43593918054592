import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { Suspense } from "react";
import { Provider } from "react-redux";
import SplashScreen from "./layout/SplashScreen/Index";

import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LoadingProvider } from "./context/LoadingContext";

import { ThemeProvider } from "@mui/material";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

import createMuiTheme from "./styles/overrides/material.override";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { DialogProvider } from "./context/DialogContext";
import { ToastProvider } from "./context/ToastContext";
import useWhitelabel, { WhitelabelHookType } from "./hooks/useWhitelabel";
import { GoogleOAuthProvider } from "@react-oauth/google";

import ReactGA from "react-ga4";

if (import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID)
}

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: 2,
        throwOnError: true,
        retryDelay: 5000,
      },
    },
  });

  const materialTheme = createMuiTheme();
  const title = useWhitelabel(WhitelabelHookType.TITLE);

  return (
    <Suspense>
      <StyledThemeProvider theme={materialTheme}>
        <ThemeProvider theme={materialTheme}>
          <Suspense fallback={<SplashScreen />}>
            <ToastProvider>
              <LoadingProvider>
                <DialogProvider>
                  <QueryClientProvider client={queryClient}>
                    <Provider store={store}>
                      <PersistGate loading={<SplashScreen />} persistor={persistor}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <HelmetProvider>
                            <Helmet
                              titleTemplate={`%s | ${title}`}
                              defaultTitle={title}
                            />
                            <GoogleOAuthProvider clientId={import.meta.env.VITE_APP_GOOGLE_ID}>
                              <RouterProvider router={router} />
                            </GoogleOAuthProvider>
                          </HelmetProvider>
                        </LocalizationProvider>
                      </PersistGate>
                    </Provider>
                  </QueryClientProvider>
                </DialogProvider>
              </LoadingProvider>
            </ToastProvider>
          </Suspense>
        </ThemeProvider>
      </StyledThemeProvider>
    </Suspense>
  );
}

export default App;
