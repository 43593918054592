import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
	name: "sidebar",
	initialState: { open: false },
	reducers: {
		toggle: (state) => {
			state.open = !state.open;
		},
		close: (state) => {
			state.open = false;
		}
	},
});

export const { toggle, close } = sidebarSlice.actions;
export default sidebarSlice.reducer;
