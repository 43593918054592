import useMessage from '@rottitime/react-hook-message-event';
import { createContext, ReactNode, useContext } from 'react';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';

const ToastContext = createContext<{
    success: (message: string, config?: ToastOptions<unknown>) => void,
    error: (message: string, config?: ToastOptions<unknown>) => void,
    info: (message: string, config?: ToastOptions<unknown>) => void,
    warning: (message: string, config?: ToastOptions<unknown>) => void,
}>({
    success: () => { },
    error: () => { },
    info: () => { },
    warning: () => { },
});

export const useToastify = () => useContext(ToastContext)

import "react-toastify/dist/ReactToastify.css";
import { JsChannelKeys } from 'src/constants/JsChannelKeys';
export const ToastProvider = (props: { children: ReactNode }) => {

    useMessage(JsChannelKeys.ALERT, (_, payload) => {
        const message = (payload.message ?? "") as string
        switch (payload.typeAlert) {
            case "success":
                toast.success(message);
                break;
            case "error":
                toast.error(message);
                break;
            case "info":
                toast.info(message);
                break;
            case "warning":
                toast.warn(message);
                break;
        }
    });

    return (
        <ToastContext.Provider value={{
            success: (message: string, config?: ToastOptions<unknown>) => toast.success(message, config),
            error: (message: string, config?: ToastOptions<unknown>) => toast.error(message, config),
            info: (message: string, config?: ToastOptions<unknown>) => toast.info(message, config),
            warning: (message: string, config?: ToastOptions<unknown>) => toast.warn(message, config),
        }}>
            {props.children}
            <ToastContainer style={{ zIndex: 11000 }} />
        </ToastContext.Provider>
    )
}