import styled from 'styled-components';

interface Props {
    inverted?: boolean;
    size?: number;
}

const Loading = ({ inverted = false, size = 30 }: Props) => {
    return (
        <Spinner>
            <div className={inverted ? "inverted spinner" : "spinner"} style={{ height: size, width: size }}></div>
        </Spinner>
    );
};

export default Loading;

const Spinner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner {
        border: 4px solid transparent;
        border-top: 4px solid ${({ theme }) => theme.palette.primary.main};
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`
