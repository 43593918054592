import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import localStorage from 'redux-persist/lib/storage'

import authReducer, { AuthResponse } from "./slices/auth.slice.ts";
import sidebarReducer from "./slices/sidebar.slice.ts";
import mobileReducer from "./slices/mobile.slice.ts";

import { persistReducer, persistStore } from "reduxjs-toolkit-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";

const encryptor = encryptTransform({ secretKey: import.meta.env.VITE_APP_SECURE_LOCAL_STORAGE_HASH_KEY })

export const store = configureStore<{
	auth: AuthResponse,
	sidebar: { open: boolean },
	mobile: { mobile: boolean }
}>({
	reducer: {
		// @ts-expect-error: Localstorage
		auth: persistReducer({ key: "auth", storage: localStorage, transforms: [encryptor] }, authReducer),
		sidebar: sidebarReducer,
		mobile: mobileReducer
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	}),
	devTools: import.meta.env.PROD
});

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;