import Loading from "src/components/Loading"
import useWhitelabel, { WhitelabelHookType } from "src/hooks/useWhitelabel"
import styled from "styled-components"

export default function SplashScreen() {

    const logo = useWhitelabel(WhitelabelHookType.LOGO)

    return (
        <Splash>
            <img src={logo} alt="Logo MeuPedágio.com" width={200} />
            <Loading />
        </Splash>
    )
}

const Splash = styled.main`
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.palette.background.paper};

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;

    .spinner {
        color: ${({ theme }) => theme.palette.secondary.main};
    }
`