export const JsChannelKeys = {
    GET_TOKEN: "getToken",
    SET_TOKEN: "setToken",
    LOGOUT: "Logout",
    LOGOUT_SYSTEM: "logoutSystem",
    COPY_TEXT: "copyText",
    OPEN_SITE: "openSite",
    GOTO_SIGNIN: "goToSignIn",
    SET_AS_MOBILE: "setAsMobile",
    ALERT: "alert",
    ENABLE_FINGERPRINT: "enableFingerprint",
    DISABLE_FINGERPRINT: "disableFingerprint",
    COMPLETE_SIGNUP_DATA: "completeSignUpData"
}
