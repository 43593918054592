document.addEventListener('DOMContentLoaded', () => {
    const userAgent = navigator.userAgent.toLowerCase();

    const isAndroid = userAgent.includes('android');
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    const isWebView = (isAndroid && userAgent.includes('wv')) || (isIOS && userAgent.includes('safari') === false);

    if(!isWebView) {
        var script = document.createElement('script');
        script.src = import.meta.env.VITE_APP_COOKIE_URL;
        document.body.appendChild(script);
    }
  });