import { WINDOW_BREAKPOINTS } from "src/constants/WindowBreakpoints";
import useWidth from "src/hooks/useWidth";

export function isMobile() {
    const width = useWidth();
    return width <= WINDOW_BREAKPOINTS.MOBILE;
}

export function isTablet() {
    const width = useWidth();
    return width <= WINDOW_BREAKPOINTS.TABLET;
}

export function isDesktop() {
    const width = useWidth();
    return width > WINDOW_BREAKPOINTS.TABLET;
}