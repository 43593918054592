import { createTheme } from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        thirty: Palette['primary'];
    }
    interface PaletteOptions {
        thirty: PaletteOptions['primary'];
    }
}

import meupedagioVariables from "src/styles/partners/meupedagio.module.scss";
import eprVariables from "src/styles/partners/epr.module.scss";
import ecoNoroesteVariables from "src/styles/partners/econoroeste.module.scss";

import { PartnerKeys } from "src/constants/PartnerKeys";

export default function createMuiTheme() {
    const clientId = import.meta.env.VITE_APP_CLIENT_ID;

    const customTheme = clientId === PartnerKeys.ECONOROESTE
        ? ecoNoroesteVariables
        : clientId === PartnerKeys.EPR
            ? eprVariables
            : meupedagioVariables;

    const theme = createTheme(customTheme ? {
        palette: {
            primary: {
                main: customTheme['primary-main'],
                dark: customTheme['primary-dark'],
            },
            secondary: {
                main: customTheme['secondary-main'],
            },
            grey: {
                "500": customTheme['grey-500'],
                "600": customTheme['grey-600'],
                "700": customTheme['grey-700'],
            },
            thirty: {
                main: customTheme['thirty-main'],
            },
            background: {
                default: customTheme['background-default'],
                paper: customTheme['background-card'],
            },
            text: {
                primary: customTheme['text-primary'],
            },
            warning: {
                main: customTheme['warning-main']
            },
            success: {
                main: customTheme['success-main']
            },
            error: {
                main: customTheme['danger-main']
            },
            info: {
                main: customTheme['info-main']
            }
        }
    } : {});

    return createTheme(theme, {
        components: {
            MuiModal: {
                styleOverrides: {
                    root: {
                        zIndex: 10000
                    }
                }
            },
            MuiButton: {
                variants: [
                    {
                        props: { variant: 'contained', color: 'primary' },
                        style: {
                            color: 'white',
                            textAlign: 'center',
                            fontFamily: 'Rubik',
                            fontSize: '1.6rem',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            letterSpacing: '-0.32px',
                            textTransform: 'none',

                            cursor: 'pointer',
                            padding: '13px 38px',
                            transition: 'all .5s ease-in-out',

                            border: `2px solid primary`,
                            borderRadius: '6px',
                            background: `linear-gradient(76deg, ${theme.palette.primary.main} 52.67%, ${theme.palette.primary.dark} 100%)`,
                            boxShadow: '0px 2px 6px 0px rgba(3, 1, 1, 0.25)',

                            ':disabled': {
                                color: theme.palette.thirty.main,
                                opacity: 0.5,
                                cursor: 'default',
                            },

                            '.spinner': {
                                borderTopColor: 'white'
                            },

                            ':hover': {
                                background: 'transparent',
                                color: theme.palette.primary.dark,
                                border: `2px solid ${theme.palette.primary.dark}`,

                                '.spinner': {
                                    borderTopColor: theme.palette.primary.dark
                                }
                            }
                        }
                    },
                    {
                        props: { variant: 'outlined' },
                        style: {
                            background: 'none',
                            border: `2px solid ${theme.palette.primary.dark}`,
                            color: theme.palette.primary.dark,
                            lineHeight: 'normal',
                            fontWeight: 600,

                            '&:hover': {
                                background: `${theme.palette.primary.main}`,
                                color: 'white',
                                borderWidth: 2
                            },

                            '&:disabled': {
                                background: 'none',
                                borderColor: theme.palette.thirty.main,
                                color: theme.palette.thirty.main,
                                cursor: 'default',
                            }
                        }
                    }
                ]
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        color: theme.palette.primary.dark,
                        fontSize: '1.4rem !important',
                        backgroundColor: 'transparent',
                    }
                }
            },
            MuiInputBase: {
                defaultProps: {
                    style: {
                        fontSize: "1.6rem"
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        fontSize: "1.6rem"
                    }
                }
            },
            MuiFormHelperText: {
                defaultProps: {
                    style: {
                        fontSize: "1.2rem",
                        marginLeft: 0,
                        whiteSpace: "nowrap"
                    }
                }
            },
            MuiCheckbox: {
                defaultProps: {
                    style: {
                        transform: "scale(1.3)"
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    head: {
                        backgroundColor: 'inherit !important',
                    },
                    root: {
                        ":hover": {
                            backgroundColor: 'rgba(93, 86, 86, .1)'
                        }
                    },
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        padding: 16
                    },
                    root: {
                        fontSize: "1.4rem"
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        borderRadius: 8,
                        width: "100%",
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        fontSize: "1.6rem"
                    }
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        fontSize: "1.4rem"
                    }
                }
            },
        }
    });
}