import { createContext, ReactNode, useContext, useState } from 'react';
import styled from "styled-components";
import { WINDOW_BREAKPOINTS } from "src/constants/WindowBreakpoints.ts";
import Loading from 'src/components/Loading';
import { Modal } from '@mui/material';
import { isMobile } from 'src/helpers/windowHelpers';

const LoadingContext = createContext({
  startLoading: () => { },
  stopLoading: () => { },
});

export const useLoading = () => useContext(LoadingContext)

export const LoadingProvider = (props: { children: ReactNode }) => {
  const [loading, setLoading] = useState(false);
  const mobile = isMobile();


  return (
    <LoadingContext.Provider value={{
      startLoading: () => setLoading(true),
      stopLoading: () => setLoading(false),
    }}>
      <Modal
        open={loading}
        disableEscapeKeyDown
        keepMounted
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999
        }}
      >
        <Container mobile={mobile}>
          <strong>Carregando...</strong>
          <span>Aguarde um momento por favor</span>
          <Loading />
        </Container>
      </Modal>
      {props.children}
    </LoadingContext.Provider>
  )
}

const Container = styled.div<{ mobile: boolean }>`
    border: none;
    margin: auto;
    padding: 32px;
    width: 500px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    background-color: white;
    border-radius: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    @media screen and (max-width: ${WINDOW_BREAKPOINTS.TABLET}px) {
      width: 80%;
    }

    strong {
      font-size: ${({ mobile }) => mobile ? '2rem' : '2.6rem'};
    }
      
    span {
      font-size: ${({ mobile }) => mobile ? '1.6rem' : '1.8rem'};
    }

    .loading {
        width: 30px;
        height: 30px;
    }
`