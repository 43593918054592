import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

export const router = createBrowserRouter([
    {
        path: "", Component: lazy(() => import("./layout/Landing/Index")),
        children: [
            { path: "", Component: lazy(() => import("./pages/LandingPage/Index")) },
            { path: "faq", Component: lazy(() => import("./pages/FAQ/Index")) },
            { path: "privacy-policy", Component: lazy(() => import("./pages/Utilies/PrivacyPolicy")) },
            { path: "terms", Component: lazy(() => import("./pages/Utilies/Terms")) },
            { path: "institucional", Component: lazy(() => import("./pages/Institucional/Index")) },
        ]
    },
    {
        path: "auth", children: [
            { path: "sign-in", Component: lazy(() => import("./pages/Auth/SignIn")) },
            { path: "confirm-phone-number", Component: lazy(() => import("./pages/Auth/ConfirmPhoneNumber")) },
            { path: "confirm-email-sent", Component: lazy(() => import("./pages/Auth/ConfirmEmailSent")) },
            { path: "sign-up", Component: lazy(() => import("./pages/Auth/SignUp")) },
            { path: "confirm-email", Component: lazy(() => import("./pages/Auth/ConfirmEmail")) },
            { path: "social/complete", Component: lazy(() => import("./pages/Auth/CompleteSocialSignUp")) }
        ]
    },
    {
        path: "app",
        Component: lazy(() => import("./layout/Dashboard/Index")),
        ErrorBoundary: lazy(() => import("./pages/Errors/Page404")),
        children: [
            { path: "dashboard", Component: lazy(() => import("src/context/PassageDashboardContext")) },
            { path: "onboarding", Component: lazy(() => import("./pages/OnBoarding/Index")) },
            { path: "historico", Component: lazy(() => import("./pages/History/Index")) },
            { path: "meus-veiculos", Component: lazy(() => import("./pages/MyVehicles/Index")) },
            // { path: "meu-cartao", Component: lazy(() => import("./pages/MyCreditCard/Index")) },
            { path: "perfil", Component: lazy(() => import("./pages/Profile/Index")) },
            {
                path: "checkout", children: [
                    { path: "", Component: lazy(() => import("./pages/Payment/Checkout/Index")) },
                    { path: ":orderId", Component: lazy(() => import("./pages/Payment/Checkout/Index")) },
                    { path: "success", Component: lazy(() => import("./pages/Payment/Finish/Index")) }
                ]
            },
            { path: "pagamento/concluido", Component: lazy(() => import("./pages/Payment/Finish/Index")) },
            { path: "pagamento/metodo", Component: lazy(() => import("./pages/Payment/PaymentMethod/Index")) },
            {
                path: "parceiro", children: [
                    { path: "pagamento/:installmentId", Component: lazy(() => import("./pages/Payment/Partner/Index")) },
                    { path: "pedido/:orderCoreId", Component: lazy(() => import("./pages/Payment/Partner/Index")) },
                ]
            },
            { path: "nfs/:invoiceNumber", Component: lazy(() => import("./pages/NFS/Index")) }
        ]
    },
    { path: "mobile/*", Component: lazy(() => import("./pages/Mobile/Index")) },
    { path: "account-deleted", Component: lazy(() => import("./pages/Utilies/AccountDeleted")) },
    // {
    //     path: "comprovante-teste", element: <Receipt order={{
    //         status: OrderStatus.PAID,
    //         orderItems: []
    //     }} />
    // },
    // { path: "iframe", Component: lazy(() => import("./pages/Iframe")) },
    // { path: "app-test", Component: lazy(() => import("./pages/AppTest")) },
    { path: "*", Component: lazy(() => import("./pages/Errors/Page404")) }
]);